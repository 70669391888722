import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 717.000000 701.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,701.000000) scale(0.100000,-0.100000)" >


<path d="M3852 5737 c-81 -30 -152 -129 -152 -213 0 -66 40 -241 135 -589 26
-95 141 -455 173 -542 41 -107 108 -163 220 -183 575 -100 1086 -516 1277
-1040 110 -302 138 -743 69 -1095 -33 -169 -137 -433 -193 -492 l-22 -23 -909
0 -910 0 0 489 c0 536 1 525 -62 596 -17 20 -48 45 -68 55 -33 17 -286 116
-435 170 -267 98 -478 169 -651 221 -147 43 -221 31 -301 -53 -67 -69 -63 18
-63 -1451 0 -980 3 -1340 12 -1368 16 -55 73 -118 132 -145 l51 -24 1965 0
1965 0 50 24 c60 27 185 155 326 332 329 411 549 867 655 1354 52 238 54 267
54 756 0 480 -1 490 -46 734 -17 87 -47 210 -82 335 -37 132 -150 416 -207
519 -12 23 -41 77 -65 121 -133 247 -392 571 -601 754 -138 121 -223 184 -354
263 -288 175 -532 277 -881 369 -368 96 -970 166 -1082 126z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
